<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/User">Pengguna</a></li>
                                <li class="breadcrumb-item active">Pengaturan Pengguna</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Pengguna</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Username <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm"
                                                    id="usernamedata" name="usernamedata" placeholder="Username"
                                                    v-model="usernamedata">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="usernamedatatmp" name="usernamedatatmp" placeholder="Username"
                                                    v-model="usernamedatatmp">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Password User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="password" class="form-control form-control-sm"
                                                    id="passworddata" name="passworddata" placeholder="Password User"
                                                    v-model="passworddata" @keyup="cekkekuatan()">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Kekuatan kata sandi</label>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                    aria-valuemin="0" aria-valuemax="100" :style="'width:'+strength+'%'"
                                                    id="persennyapassword">
                                                    <span id="tulisanpersennyapassword">{{strength}}%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Nama User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="namadata"
                                                    name="namadata" placeholder="Nama User" v-model="namadata">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Level User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="leveluser" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedleveluser"
                                                @update:modelValue="onleveluserChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kab./Kota<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="kabkot" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkabkot" @update:modelValue="onkabkotChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.." :disabled="(strength < 60)"><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.." :disabled="(strength < 60)"><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            dleveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            levelkabkot: JSON.parse(localStorage.getItem("bdlsistem")),
            levelkelurahan: JSON.parse(localStorage.getItem("sbdsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            usernamedata: '',
            usernamedatatmp: '',
            namadata: '',
            passworddata: '',
            leveluser: [],
            dataleveluser: [],
            defaultSelectedleveluser: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedleveluser: 0,
            kabkot: [],
            datakabkot: [],
            defaultSelectedkabkot: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkabkot: 0,
            validasi: false,
            strength: 0,
        }
    },
    methods: {
        cekkekuatan() {
            //alert(this.passworddata);
            this.strength = 0;
            var lowerCase = this.passworddata.match(/[a-z]/);
            var upperCase = this.passworddata.match(/[A-Z]/);
            var numbers = this.passworddata.match(/[0-9]/);
            var width = this.passworddata.length;
            var specialCharacters = this.passworddata.match(/[\!\~\@\&\#\$\%\^\&\*\(\)\{\}\?\-\_\+\=]/);
            if (width > 8) {
                this.strength = this.strength + 20;
            }
            if (lowerCase) {
                this.strength = this.strength + 20;
            }
            if (upperCase) {
                this.strength = this.strength + 20;
            }
            if (numbers) {
                this.strength = this.strength + 20;
            }
            if (specialCharacters) {
                this.strength = this.strength + 20;
            }
            
            document.getElementById('persennyapassword').style.width = this.strength + "%";
            document.getElementById('tulisanpersennyapassword').innerHTML = this.strength + "%";
            //alert(document.getElementById('tulisanpersennyapassword').innerHTML);
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        onleveluserChange(a) {
            try {
                this.selectedleveluser = this.leveluser[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkabkotChange(a) {
            try {
                this.selectedkabkot = this.kabkot[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/User");
        },
        async AmbilData() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIData.get("parastapainnovation-GetAllLevel?level=" + this.dleveluser + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataleveluser = [];
                        this.leveluser = [];
                        this.dataleveluser = Response.data.content.data;
                        this.dataleveluser.forEach((item) => {
                            this.leveluser.push({
                                label: item.nama,
                                code: this.dataleveluser.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.leveluser.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            var random = Math.random();
            await mainAPIData.get("parastapainnovationLokasi-GetLokasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.Nama,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kabkot.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIData.get("parastapainnovationLokasi-GetLokasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.Nama,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kabkot.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
            var random = Math.random();
            await mainAPIData.get("parastapainnovation-GetAllLevel?level=" + this.dleveluser + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataleveluser = [];
                        this.leveluser = [];
                        this.dataleveluser = Response.data.content.data;
                        this.dataleveluser.forEach((item) => {
                            this.leveluser.push({
                                label: item.nama,
                                code: this.dataleveluser.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.leveluser.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })


            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovationuserNonMD5-GetUser?kduser=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.usernamedata = Response.data.content.data[0].username;
                    this.usernamedatatmp = Response.data.content.data[0].username;
                    this.namadata = Response.data.content.data[0].nama;
                    this.passworddata = Response.data.content.data[0].password;

                    this.defaultSelectedleveluser = {
                        code: Response.data.content.data[0].level,
                        label: Response.data.content.data[0].namaleveluser,
                        tag: Response.data.content.data[0].level,
                    };
                    this.selectedleveluser = Response.data.content.data[0].level;
                    this.halamanloading = false;

                    this.defaultSelectedkabkot = {
                        code: Response.data.content.data[0].bidang,
                        label: Response.data.content.data[0].Kabkot,
                        tag: Response.data.content.data[0].bidang,
                    };
                    this.selectedkabkot = Response.data.content.data[0].bidang;
                    this.cekkekuatan();
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('usernamedata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('usernamedata').focus();
                return false;
            }
            if (document.getElementById('passworddata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('passworddata').focus();
                return false;
            }
            if (document.getElementById('namadata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('namadata').focus();
                return false;
            }
            if (this.selectedleveluser == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                return false;
            }
            if (this.selectedkabkot == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("username", this.usernamedata);
            fd.append("password", this.passworddata);
            fd.append("nama", this.namadata);
            fd.append("level", this.selectedleveluser);
            fd.append("kabkot", this.selectedkabkot);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovationuser-AddUser", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/User");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("username", this.usernamedata);
            fd.append("usernametmp", this.usernamedatatmp);
            fd.append("password", this.passworddata);
            fd.append("nama", this.namadata);
            fd.append("level", this.selectedleveluser);
            fd.append("kabkot", this.selectedkabkot);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovationuser-EditUser", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/User");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.cekkekuatan();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
        else {
            this.AmbilData();
        }
    }
}
</script>
<style></style>